import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const About = () => {
    return (
        <Box sx={{
            p: '1rem',
            pb: '2rem',
            backgroundColor: '#EEF6F5'
        }}>
            <Box sx={{
                maxWidth: '85rem',
                margin: 'auto'
            }}>
                <Typography variant='h5' sx={(theme) => ({
                    color: 'text.secondary',
                    fontWeight: '100',
                    [`${theme.breakpoints.up('sm')}`]: {
                        marginBottom: '3rem'
                    }
                })}>
                    About MediRadar
                </Typography>
                <Box sx={(theme) => ({
                    display: 'flex',
                    gap: '3rem',
                    padding: 0,
                    alignItems: 'center',
                    [`${theme.breakpoints.down('md')}`]: {                        
                        flexWrap: 'wrap',
                    }
                })}>
                    <Box sx={(theme) => ({
                        width: '100%',
                        flexBasis: '100%',
                        [`${theme.breakpoints.up('md')}`]: {
                            flexBasis: '50%'
                        }
                    })}>
                        <Box sx={{
                            position: 'relative',
                            paddingBottom: '56.25%',
                            // paddingTop: '25px',
                            height: 0
                        }}>
                            <iframe src="https://www.youtube.com/embed/HhN5FKRYvW8?si=TBRCO2A8bnIRJLZY"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen="allowfullscreen"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 'auto',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={(theme) => ({
                        flexBasis: '100%',
                        [`${theme.breakpoints.up('md')}`]: {
                            flexBasis: '50%'
                        }
                    })}>
                        <Typography sx={() => ({
                            overflow: 'hidden',
                            position: 'relative',
                        })}>                        
                            <p>
                                MediRadar is an online platform dedicated to improving access 
                                to high quality medical imaging for patients, through our customer friendly website, 
                                and easy search and bookings process.
                            </p>
                            <p>
                                MediRadar allows patients to search for affordable, state of the art imaging facilities near them,
                                and book scans immediately with rapid confirmation of appointments. 
                                This allows patients to get answers quickly and avoid long wait times within the NHS. 
                                The platform allows patient customers to also link in with relevant specialists, 
                                before or after their scan through a video consultation, or face-to-face consultation near them
                            </p>
                            <p>
                                Our passion is to deliver a seamless experience, enabling the first steps towards recovery and better health.
                                MediRadar can assist patients embarking on health promotion and screening, which can enable early detection of treatable conditions.
                                MediRadar, empowering patients, improving health.
                            </p>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default About;