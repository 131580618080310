import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

// TODO :         // padding: theme.spacing(3),

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(3),
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    listHeading: {
        listStyleType: 'none',
        left: '-1rem',
        position: 'relative',
    }
}));

const TermsAndConditions = (props) => {
    const classes = useStyles();
    const { open, handleClose } = props;

    return (
        <React.Fragment>
            <Dialog fullScreen onClose={handleClose} open={open}>
                <DialogTitle disableTypography className={classes.title}>
                    <Typography variant="h5">Terms and Condition Policy for MediRadar</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography >
                        <ul style={{ "listStyleType": "decimal" }}>
                            <li>
                                <p>
                                    MediRadar (mediradar.com) is a subsidiary of MediRadar Limited, a company registered in England and Wales under company number 12603195.
                                </p>
                            </li>
                            <li>
                                <p>By using this website you confirm that you accept these terms and conditions and agree to be bound by them. If you do not agree with the terms and conditions below, please leave this website.</p>
                            </li>
                            <li>
                                <p>These terms should be read in conjunction with MediRadar Limited’s Privacy Policy, which sets out the terms on which MediRadar Limited processes any personal data collected from you or that you provide to us. By using MediRadar Limited’s website, you confirm that you consent to the processing of the personal data and you warrant that the data provided is accurate.</p>
                            </li>
                            <li>
                                <p>
                                    These terms should also be read in conjunction with MediRadar Limited’s cookie Policy, which sets out information about how MediRadar Limited uses cookies on its website.
                                </p>
                            </li>
                            
                            <li>
                                <p>
                                    MediRadar/MediRadar Limited reserves the right to make changes to its terms and conditions at any time. Any amendments to the terms and conditions will be binding on you.
                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Use
                                </Typography>
                            </li>
                            <li>
                                <p>
                                    MediRadar searches for imaging facilities and their scan prices near you. Mediradar aims to deliver transparent and rapid access to medical scan facilities and costs near you and facilitate bookings. Any information contained on this site should not replace advice that your relevant health professional would give you and is not intended to amount to advice on which you should rely. We use CQC Ratings to find rated facilities near you. MediRadar Limited makes no representations, warranties or guarantees that the information or content on referral website is accurate, complete or up to date
                                </p>
                            </li>
                            <li>
                                <p>
                                    You may not copy, print out, download or otherwise reproduce any of the information on this site other than for your personal, non-commercial use.
                                </p>
                            </li>
                            <li>
                                <p>
                                    MediRadar Limited endeavours to make this site available at all times however cannot be held responsible for unforeseen unavailability of service and will not be liable to you for any reason if the website is unavailable at any time for any period. MediRadar Limited may update the website from time to time and may change the content at any time. MediRadar Limited may also suspend, withdraw or discontinue the website without notice.
                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Accuracy of content
                                </Typography>
                            </li>
                            <li>
                                <p>
                                    Whilst MediRadar Limited tries to ensure the content within its website is accurate some may become out of date and subject to change without notice. MediRadar Limited is not under any obligation to update the website and does not guarantee that the website or any content on the website will be free from errors or omissions.                                </p>
                            </li>
                            <li>
                                <p>
                                    Published prices are guide prices only. A full price can only from the site being referred to.                                </p>
                            </li>
                            <li>
                                <p>
                                    If you find any inaccurate information on the website please email: info@mediradar.com
                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Intellectual Property
                                </Typography>
                            </li>
                            <li>
                                <p>
                                    All content, trade names and marks are owned or licensed by MediRadar Limited Reproduction of content, trademarks, HTML code or logos in part or whole is strictly prohibited and you acknowledge that you do not acquire any ownership rights by downloading or printing copyrighted material
                                </p>
                            </li>
                            <li>
                                <p>
                                    You must not modify the paper or digital copies of any material downloaded from the MediRadar Limited website in any way. Illustrations, photographs or any graphics must not be used separately from any accompanying text. The status of MediRadar Limited and any identified contributors as the authors of the content on the website must always be acknowledged.

                                </p>
                            </li>
                           
                            <li>
                                <p>
                                    You must not use any part of the website or its content for commercial purposes without obtaining a licence to do so from MediRadar Limited or its licensors.

                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Limitation of MediRadar Limited’s liability

                                </Typography>
                            </li>
                            <li>
                                <p>
                                    To the extent permitted by law, MediRadar Limited excludes all warranties, representations, conditions or other terms, whether express or implied in relation to content on the website. MediRadar Limited will not be liable for any loss or damage, whether in contract, tort, breach of statutory duty or otherwise, arising from or in connection with (i) the use or inability to use the website or (ii) the use of or reliance on any content shown on the website. MediRadar Limited shall have no liability for loss of profits, loss of business, business interruption, loss of business opportunity or any indirect or consequential loss or damage.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Nothing in these terms and conditions will exclude or limit MediRadar Limited’s liability for death or personal injury arising from MediRadar Limited’s negligence or for any fraud or fraudulent misrepresentation or any other liability that cannot be excluded or limited by English law.

                                </p>
                            </li>
                            <li>
                                <p>
                                    MediRadar Limited accepts no responsibility for any loss or damage due to viruses that infect your computer or data arising from your use of this website.

                                </p>
                            </li>
                            <li>
                                <p>
                                    MediRadar Limited will not be responsible for the content of any websites that are linked on MediRadar Limited’s website. MediRadar Limited will not be liable for any loss or damage that may arise from use of any such linked websites.

                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Viruses

                                </Typography>
                            </li>
                            <li>
                                <p>
                                    You must not misuse MediRadar Limited’s website by knowingly introducing viruses or other material which is malicious or technologically harmful. MediRadar Limited will report any such acts or unauthorised access to the website or the servers to the relevant law enforcement authorities.

                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Linking to this website

                                </Typography>
                            </li>
                            <li>
                                <p>
                                    You may link to the MediRadar Limited website but by doing so, MediRadar Limited accepts no association, approval or endorsement of your website and you must not suggest that any form of association, approval or endorsement exists on MediRadar Limited’s part unless otherwise expressly agreed.

                                </p>
                            </li>
                            <li>
                                <p>
                                    MediRadar Limited reserves the right to ask you to remove any links to the MediRadar Limited site at any time if we believe that our reputation is being damaged or internet users are being misled. MediRadar Limited reserves the right to ask you to remove any links to the MediRadar Limited site at any time if we believe that our reputation is being damaged or internet users are being misled.
                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Third party links and resources
                                </Typography>
                            </li>
                            <li>
                                <p>
                                    There may be links to other websites and resources provided by third parties on the MediRadar Limited website. The links are provided for price comparison and facilitating a search for your purposes only and MediRadar Limited has no control over the contents of third party sites or resources.

                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Applicable law

                                </Typography>
                            </li>
                            <li>
                                <p>
                                    These terms and conditions are governed by English law.

                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Trademarks
                                </Typography>
                            </li>
                            <li>
                                <p>
                                    MediRadar Limited are all trademarks of MediRadar Limited
                                </p>
                            </li>
                            <li className={classes.listHeading}>
                                <Typography variant="h6" >
                                    Contact Us
                                </Typography>
                            </li>
                            <li>
                                <p>
                                    To contact MediRadar Limited, please email info@mediradar.com

                                </p>
                            </li>

                        </ul>
                    </Typography>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default TermsAndConditions;
