import React from 'react';
import { Routes, Route } from "react-router-dom";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Header from './components/Header';
import HomePageMainSearch from './components/HomePageMainSearch';
import About from './components/About';
import AboutStatic from './components/AboutStatic';
import AboutScansStatic from './components/AboutScansStatic';
import Testimonials from './components/Testimonials';
import ImgMediaCard from './components/ImgMediaCard';
import FooterStatic from './components/FooterStatic';
import useSmUp from './hooks/useSmUp';
import BlogPost from "./components/BlogPost";
import { buildRoute } from './utils';

const SmallDivider = () => {
	const matches = useSmUp();

	return (!matches &&
		<Divider sx={{
			width: '5rem !important',
			borderColor: '#000000',
			margin: '2rem 1rem'
		}} />
	);
};

const App = () => {
	return (
		<Routes>
			{['/', '/clp'].map(path => (
				<Route path={path} element={
					<Box sx={(theme) => ({
						[`${theme.breakpoints.up('sm')}`]: {
							margin: 'auto',
						}
					})}
					>
						<Header />
						<HomePageMainSearch />
						<SmallDivider />
						<About />
						<SmallDivider />
						<Testimonials />
						<ImgMediaCard />
						<FooterStatic />
					</Box>						
				} />
			))}
			<Route path={`${buildRoute('/content/aboutus')}`} element={
				<Box>
					<Header />
					<Box sx={{mt: '1rem'}} />
					<AboutStatic />
					<FooterStatic />
				</Box>
			} />
			<Route path={`${buildRoute('/content/aboutscans')}`} element={
				<Box>
					<Header />
					<Box sx={{mt: '1rem'}} />
					<AboutScansStatic />
					<FooterStatic />
				</Box>
			} />
			{/* <Route path={`${buildRoute('/blog/:slug')}`} element={
				<Box>
					<Header />
					<Box sx={{mt: '1rem'}} />
					<BlogPost />
					<FooterStatic />
				</Box>
			} /> */}
			<Route path={`${buildRoute('/blog/:slug')}`} element={
				<Box>
					<Header />
					<Box sx={{mt: '1rem'}} />
					<BlogPost />
					<FooterStatic />
				</Box>
			} />
		</Routes>
	);
};

export default App;
