import React from 'react';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import useSmUp from './../hooks/useSmUp'

const HomePageContentStyled = styled('div')(({ theme }) => ({
   padding: '1rem'
}));

const HomePageContent = props => {
    const matches = useSmUp();

    return ( 
        <HomePageContentStyled>
            <Typography variant={matches ? 'h2' : 'h4'} gutterBottom sx={{
                fontWeight: '100'
            }}>
                Empowering patients Improving health
            </Typography>
            <Typography variant='h5' sx={{ color: 'text.secondary', fontWeight: '100' }}>
                Affordable access to high quality private healthcare
            </Typography>
        </HomePageContentStyled>
    );
};

export default HomePageContent;