import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import throttle from 'lodash/throttle';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import useStores from '../stores/useStores';
import { observer } from "mobx-react-lite"


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 0px 10px 0px #aac4da',
        width: '15rem'
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center',
        width: "100%"
    },
    places: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    searchHeader: {
        width: '100%',
        justifyContent: 'space-between'
    },
    marginLeft: {
        // marginLeft: '2rem'
    },
    svg: {
        width: '2rem',
        height: '2rem',
        marginLeft: '.5rem'
    },
    underline: {
        '&:after': {
            border: 'none'
        },
        '&:before': {
            border: 'none'
        }
    }
}));

const PinCodeSearch = observer((props) => {
    const classes = useStyles();
    const [, setFocusState] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [pinCodes, setPinCodes] = React.useState([]);
    const [selectedPinCode, setSelectedPinCode] = React.useState('');
    const [, setSelectedRegion] = React.useState();
    const [, setSelectedGeoCodes] = React.useState({});
    const [, setSelectedPinCodeDetails] = React.useState({});
    const { commonStore } = useStores();
    let autoCompleteRef;

    const fetchPinCodes = async (query) => {
        try {

            const url = `https://postcodes.io/postcodes/${query}/autocomplete`
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const pinCodeJson = await response.json();
            if (pinCodeJson.result) {
                setPinCodes(pinCodeJson.result);
            } else {
                setPinCodes([]);
            }

        } catch (error) {
            console.log('Error:', error);
        }
    }

    const fetchPin = React.useMemo(
        () =>
            throttle(async (request, callback) => {
                await fetchPinCodes(request);
            }, 200),
        [],
    );

    useEffect(() => {
        if (inputValue.trim().length > 0) {
            setTimeout(() =>
            fetchPin(inputValue.trim())
            );
        }
    }, [inputValue]); // eslint-disable-line

    useEffect(() => {
        setFocusState(true);
        autoCompleteRef && autoCompleteRef && autoCompleteRef.focus();
    }, [pinCodes]); // eslint-disable-line

    const fetchGeoCodeByPinCodeAPI = async (postcode) => {
        try {
            const url = `https://postcodes.io/postcodes/${postcode}`;
            const response = await fetch(url, {
                method : 'GET',
                headers : {
                    'Content-Type' : 'application/json',
                },
            });
            
            return await response.json();

        } catch (error) {
            console.log('Error:', error);
        }
    }

    const fetchGeoCodeByPinCode = async (pinCode) => {
        const pinCodesRes = await fetchGeoCodeByPinCodeAPI(pinCode)

        if (pinCodesRes.result) {
            setSelectedPinCodeDetails(pinCodesRes.result);
            const geolocation = {
                "latitude": pinCodesRes.result.latitude,
                "longitude": pinCodesRes.result.longitude
            };
            commonStore.latitude = pinCodesRes.result.latitude;
            commonStore.longitude = pinCodesRes.result.longitude;

            await setOtherGeolocationFn(geolocation);

        } else {
            setSelectedPinCodeDetails([]);
        }
    }

    const setRegion = async (value) => {
        const { latitude, longitude } = value;

        const baseUrl = "https://postcodes.io/postcodes/";
        let url = `${baseUrl}?lon=${longitude}&lat=${latitude}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json();

        return result['result']
            ? result.result.length
                ? result.result[0].region
                : result.result.region
            : '';
    }


    const setOtherGeolocationFn = async (value) => {
        setSelectedGeoCodes(value);
        setSelectedRegion(await setRegion(value));
    }

    return (
        <div className={classes.flexItem}>
            <Autocomplete
                autoComplete
                required
                fullWidth
                className={classes.marginLeft}
                id="pin-code-search"
                options={pinCodes}
                getOptionLabel={(option) => option}
                filterOptions={(x) => x}
                value={selectedPinCode}
                onChange={async (event, newValue) => {
                    await fetchGeoCodeByPinCode(newValue);
                    commonStore.pinCode = newValue
                    setSelectedPinCode(newValue);
                    setFocusState(false);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => <TextField
                    {...params}
                    inputRef={input => {
                        autoCompleteRef = input;
                    }}
                    label="Enter post code *"
                    variant="filled"
                    margin="normal" />
                }
            />
            <LocationOnOutlinedIcon className={classes.svg} />
        </div>
    );
});

export default PinCodeSearch;
