import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';

import AuthorBio from './AuthorBio';

export default function BlogPost() {
    const location = useLocation();
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const { author, publishedAt } = post || {};
    const date = new Date(publishedAt?.split(' ')[0]);
    const [target, setTarget] = React.useState(null);

    const showAuthorBio = e => {
        e.stopPropagation();
        e.preventDefault();
        setTarget(e.currentTarget);
    };

    const handleCloseBio = e => {
        e.stopPropagation();
        e.preventDefault(); 
        setTarget(null);
    };

    useEffect(() => {
        if (location?.pathname?.includes('blog')) {
            (async() => {
                const result = await fetch(
                    `https://api.dropinblog.com/v1/json/post/?b=77525ecd-de31-4e80-921d-e1d30e433cd4&post=${slug}`
                );
                const postJson = await result.json();
                setPost(postJson.data?.post);
            })();
        }   
    }, [location, slug]);

    return (
        <Box sx={{
            maxWidth: '60rem',
            margin: 'auto',
            p: '1rem'
        }}>
            <Typography variant="h4" gutterBottom>
                {post?.title}
            </Typography>
            {post?.author &&
                <div onClick={e => showAuthorBio(e)}>
                    <Typography variant="body2" color="text.secondary" sx={{
                        visibility: author?.length !== 0 ? 'visible' : 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        cursor: 'pointer',
                        '& img': {
                            width: '2.75rem',
                            height: '2.75rem',
                            objectFit: 'cover',
                            borderRadius: '50%'
                        }
                    }}>
                        <img src={post?.author?.photo} alt={post?.author?.name}/>
                        <div>
                            {post?.author.name}
                            <div>
                                {post?.readtime}
                                <CircleIcon sx={{
                                    width: '.5rem',
                                    height: '.5rem',
                                    ml: '.5rem',
                                    mr: '.5rem'
                                }}/>
                                {date.toLocaleString('default', { month: 'short' })}
                                {' ' + date.getDate()}
                            </div>
                        </div>                        
                    </Typography>
                </div>
            }
            {post?.content &&
                <Box dangerouslySetInnerHTML={{__html: post?.content}} sx={{
                    '& img': {
                        maxWidth: '100%'
                    }
                }}/>
            }
            {post?.author && <AuthorBio target={target} onClose={handleCloseBio} author={post?.author}/>}
        </Box>
    );
};