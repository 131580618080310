import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useBreakpoints = (key, fn) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints[fn](key));

    return matches;
};

export default useBreakpoints;