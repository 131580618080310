import { useState, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import useStores from '../stores/useStores';
import { observer } from "mobx-react-lite"


import { getModalityDetails } from '../APIs/APIClient';
import rxIcon from './images/rx.svg';

const formControl = {
    flexBasis: '33%'
};

const SearchScans = observer(props => {
    const [modality, setModality] = useState('');
    const [bodyArea, setBodyArea] = useState('');
    const [bodyRegion, setBodyRegion] = useState('');

    const [bodyAreaArray, setBodyAreaArray] = useState([]);
    const [bodyRegionArray, setBodyRegionArray] = useState();
    const [modalityDetails, setModalityDetails] = useState([]);
    const [rxFlag, setRxFlag] = useState(false);
    const { commonStore } = useStores();

    const loadBodyArea = (modality, bodyArea) => {
        if (modality) {
            setBodyAreaArray(modalityDetails[modality]);
        }

        if (bodyArea && bodyAreaArray) {
            setBodyRegionArray(bodyAreaArray[bodyArea]);
        }
    };

    useEffect(() => {
        commonStore.scanType = modality;
        commonStore.bodyArea = bodyArea;
        commonStore.BodyRegion = bodyRegion;
        commonStore.rxFlag = rxFlag;
    }, [modality, bodyArea, bodyRegion]); // eslint-disable-line


    useEffect(() => {
        const getModalityDetAPI = async () => {
            const response = await getModalityDetails();
            setModalityDetails(response);
            commonStore.modalities = response;
        }
        getModalityDetAPI();
    }, []); // eslint-disable-line

    return (
        <Box sx={(theme) => ({
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            gap: '1rem',
            justifyContent: 'space-around'
        })}>
            <FormControl variant="filled" sx={formControl}>
                <InputLabel>Type of scan</InputLabel>
                <Select
                    value={modality}
                    onChange={e => {
                        const value = e.target.value;
                        setModality(value);
                        loadBodyArea(value);
                        setRxFlag(modalityDetails[value].rxFlag);
                        setBodyArea('');
                        setBodyRegion('');
                    }}
                    IconComponent={ExpandMoreOutlinedIcon}
                >
                    {modalityDetails && Object.entries(modalityDetails).map(([key, value]) =>
                        <MenuItem value={key} key={key}>
                            <>
                                {key}
                                {
                                    modalityDetails[key].rxFlag && <ListItemIcon
                                        style={{
                                            position: "absolute",
                                            right: 0
                                        }}>
                                        <img src={rxIcon} alt="Prescription required" />
                                    </ListItemIcon>
                                }
                            </>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

            <FormControl variant="filled" sx={formControl} disabled={
                bodyAreaArray?.length === 0
            }>
                <InputLabel>Body area</InputLabel>
                <Select
                    value={bodyArea}
                    onChange={e => {
                        const value = e.target.value
                        setBodyArea(value);
                        setBodyRegion('');
                        loadBodyArea(null, value);
                    }}
                    IconComponent={ExpandMoreOutlinedIcon}
                >
                    {bodyAreaArray && Object.entries(bodyAreaArray).map(([key, value]) =>
                        key !== 'rxFlag' && <MenuItem value={key} key={key}>
                            <>
                                {key}
                                {
                                    bodyAreaArray[key].rxFlag && <ListItemIcon
                                        style={{
                                            position: "absolute",
                                            right: 0
                                        }}>
                                        <img src={rxIcon} alt="Prescription required" />
                                    </ListItemIcon>
                                }
                            </>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
           
            <FormControl variant="filled" sx={formControl} disabled={
                bodyRegionArray?.bodyRegion?.length === 0
            }>
                <InputLabel>Region</InputLabel>
                <Select
                    value={bodyRegion}
                    onChange={e => {
                        const value = e.target.value;
                        setBodyRegion(value);
                    }}
                    IconComponent={ExpandMoreOutlinedIcon}
                >
                    {bodyRegionArray && bodyRegionArray.bodyRegion.map(value =>
                        <MenuItem value={value} key={value}>{value}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
});

export default SearchScans;
