import { makeAutoObservable } from "mobx";

class CommonStore {
    scanType = "";
    bodyRegion = "";
    bodyArea = "";
    latitude = "";
    longitude = "";
    otherGeolocationName = "";
    currentAddress = "";
    rxFlag = false;
    pinCode = ""
    openFindConsultationAlert = false;
    specialty = "";
    authorBio = "";

    constructor() {
        makeAutoObservable(this)
    }

}

export default CommonStore;