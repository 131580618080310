export const getModalityDetails = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    const response = await fetch(`${process.env.REACT_APP_CONSULTME_API_URL}/modalityBodyAreaRegion`, requestOptions);
    const result = await response.json();
    return result[0].modality_details
    // return res/s/ult;
};
