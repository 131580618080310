import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const AboutScansStatic = () => {
    const [aboutScans, setAboutScans] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location?.pathname?.includes('aboutscans')) {
            (async() => {
                const result = await fetch(
                    'https://api.dropinblog.com/v1/json/post/?b=77525ecd-de31-4e80-921d-e1d30e433cd4&post=about-scans'
                );
                const aboutScans = await result.json();
                setAboutScans(aboutScans);
            })();
        }   
    }, [location]);

    return (
        <Box sx={{
            p: '1rem',
            backgroundColor: '#DCF1F0'
        }}>
            {aboutScans &&
                 <Box sx={{
                    maxWidth: '85rem',
                    margin: 'auto'
                }}>
                     <Typography variant='h5' sx={(theme) => ({
                        color: 'text.secondary',
                        fontSize: '2rem',
                        fontWeight: 'bold'
                    })}>
                        About scans
                    </Typography>
                    <div dangerouslySetInnerHTML={{__html: aboutScans?.data?.post?.content}} />
                </Box>
            }
        </Box>
    );
};

export default AboutScansStatic;