import React from 'react';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { buildRoute } from '../utils';

export default function FooterStatic() {
    const navigate = useNavigate();
    const openLink = url => window.open(url, '_blank');

    const handleClick = (e, slug) => {
        e.preventDefault();
        navigate(buildRoute(`/content/${slug}`))
    };

    return (
        <Box sx={(theme) => ({
            p: '1rem',
            pt: 0,
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            alignItems: 'center', 
            backgroundColor: '#EBEBEB',
            [`${theme.breakpoints.up('sm')}`]: {
                p: 0,
                margin: 'auto',
                marginTop: '1rem',
                flexFlow: 'row wrap',
                justifyContent: 'space-around',
            }
        })}>
            <Box sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                mt: '1rem',
                cursor: 'pointer',
                justifyContent: 'center',
                width: '100%',
            })}>
                <Link href="#" onClick={e => handleClick(e, 'aboutus')}>About us</Link>
                <Link href="#" onClick={e => handleClick(e, 'aboutscans')}>About scans</Link>
                <Link href="mailto:info@mediradar.com?subject=Contact us&body=Please leave your name, phone number and brief enquiry and our team will get back to you at the earliest opportunity.">Contact us</Link>                
            </Box>
            <Box sx={theme => ({
                [`${theme.breakpoints.only('sm')}`]: {
                   marginBottom: 0,
                   marginTop: 0,
                   gap: '1rem',
                   pl: '1rem',
                   pr: '1rem'
                },
                [`${theme.breakpoints.up('sm')}`]: {
                    width: '100%',
                    maxWidth: '85rem',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '3rem'
                }
            })}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Typography variant='title' component="div" sx={(theme) => ({
                        color: 'text.secondary',
                        fontWeight: '10',
                        mt: '2rem',
                        [`${theme.breakpoints.only('sm')}`]: {
                            width: '10rem'
                        },
                        [`${theme.breakpoints.up('sm')}`]: {                            
                            mb: '2rem'
                        }
                    })}>
                        Ⓒ {(new Date()).getFullYear()} MediRadar. All rights reserved.
                    </Typography>
                </Box>
                <Box sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    [`${theme.breakpoints.down('sm')}`]: {
                        mt: '1rem',
                    }
                })}>
                    <FacebookIcon sx={{ color: '#4267B2' }} onClick={() => openLink('https://facebook.com/100070143116463/')}/>
                    <TwitterIcon sx={{ color: '#1DA1F2' }} onClick={() => openLink('https://twitter.com/MediRadar')}/>
                    {/* <WhatsAppIcon sx={{ color: '#25D366' }} /> */}
                    <LinkedInIcon  sx={{ color: '#0077b5' }} onClick={() => openLink('https://www.linkedin.com/in/mediradar/')}/>
                    <YouTubeIcon sx={{ color: '#FF0000' }} onClick={() => openLink('https://www.youtube.com/channel/UCXfUWJvHJIrIi-5MloxpNnA')}/>
                </Box>
            </Box>            
        </Box>
    );
}