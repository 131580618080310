import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: {
			main: '#19BFB7',
			contrastText: '#fff'
		},
		secondary: {
			main: '#19857b',
		}
	}
});

const AppContext = createContext({});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={theme}>
		<AppContext.Provider value={{
			scanType: "",
			bodyRegion: "",
			bodyArea: "",
			latitude: "",
			longitude: "",
			otherGeolocationName: "",
			currentAddress: ""
		}}>
			<CssBaseline />
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</AppContext.Provider>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { AppContext };

