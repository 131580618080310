import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from 'react-slick';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import AuthorBio from './AuthorBio';
import { buildRoute } from '../utils';
import useStores from '../stores/useStores';

const sx = (mobile) => ({
    height: mobile ? '14rem' : '17rem',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
});

export default function ImgMediaCard() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const mobileUp = useMediaQuery(theme.breakpoints.up('sm'));
    const tabDown = useMediaQuery(theme.breakpoints.down('md'));
    const tab = mobileUp && tabDown;
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const [posts, setPosts] = React.useState([]);
    const { commonStore } = useStores();

    useEffect(() => {
        (async() => {
            const result = await fetch(
                'https://api.dropinblog.com/v1/json/?b=77525ecd-de31-4e80-921d-e1d30e433cd4&includecontent=1&limit=3'
            );
            const dropinPosts = await result.json();
            setPosts(dropinPosts?.data?.posts || []);
        })();
    }, []);

    const BlogCard = ({ post }) => {
        const navigate = useNavigate();
        const { title, author, featuredImage, categories, slug, summary } = post;
        const category = categories[0]?.title;
        const [target, setTarget] = React.useState(null);

        const showAuthorBio = e => {
            e.stopPropagation();
            e.preventDefault();
            setTarget(e.currentTarget);
        };

        const handleClose = e => {
            e.stopPropagation();
            e.preventDefault(); 
            setTarget(null);
        };

        const handleClick = (e, slug) => {
            e.preventDefault();
            navigate(`${buildRoute(`/blog/${slug}`)}`);
            commonStore.authorBio = post?.author?.bio;
        };

        return (
            <Card onClick={(e) => handleClick(e, slug)} sx={{
                cursor: 'pointer',
                maxWidth: '500',
                boxShadow: mobile ? 'none' : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
            }}
                key={slug}
            >
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="300"
                    src={featuredImage}
                />
                <CardContent sx={sx(mobile)}>
                    <Typography variant="h8" component="div" sx={{
                        color: 'text.secondary',
                        fontWeight: '100',
                        marginBottom: '1rem'
                    }}>
                        {category}
                    </Typography>
                    <Typography variant="h5" component="div" sx={{
                        color: 'text.secondary',
                        fontWeight: '100',
                        marginBottom: '1rem'
                    }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{
                        mb: '1rem',
                        maxHeight: '2.8rem',
                        overflow: 'hidden'
                    }}>
                        {summary}
                    </Typography>
                    {author ? (
                        <div onClick={e => showAuthorBio(e)}>
                            <Typography variant="body2" color="text.secondary" sx={{                        
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                '& img': {
                                    width: '2.5rem',
                                    height: '2.5rem',
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                }
                            }}>
                                <img src={author.photo} alt={author.name}/>
                                {author.name}
                            </Typography>
                            <AuthorBio target={target} onClose={handleClose} author={author}/>
                        </div>
                    ) : (
                        <Box sx={{ width: '2.5rem', height: '2.5rem' }}></Box>
                    )}
                </CardContent>
            </Card>
        )
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false
    };

    return (
        <Box sx={theme => ({
            p: '1rem',
            pb:  mobile ? '1.5rem' : '1rem'
        })}>
            <Box sx={theme => ({
                [`${theme.breakpoints.up('sm')}`]: {
                    maxWidth: '85rem',
                    margin: 'auto'
                }
            })}>
                <Typography variant='h5' sx={{
                    color: 'text.secondary',
                    marginBottom: '2rem',
                    fontSize: '2rem',
                    fontWeight: 'bold'
                }}>
                    Popular topics on MediRadar
                </Typography>

                <Box sx={{
                    flexGrow: 3,
                    alignItems: 'center',
                    border: mobile ? '1px solid #ACACAC' : '1px',
                    borderRadius: '4px',
                    opacity: 1,
                    overflowY: tab ? 'hidden' : 'inherit',
                    paddingBottom: tab ? '1rem' : 'inherit'
                }}>

                    {mobile &&
                        <Slider {...settings}>
                            {posts.length > 0 && posts.map(post => {
                                return (<BlogCard post={post} />);
                            })}
                        </Slider>
                    }

                    {(tab || desktop) &&
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                width: tab ? '1500px' : 'auto'
                            }}
                        >
                            {posts.length > 0 && posts.map(post => {
                                return (
                                    <Grid item xs={4}>
                                        <BlogCard post={post} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                </Box>
            </Box>
        </Box>
    );
};