import Slider from 'react-slick';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GlobalStyles from '@mui/material/GlobalStyles';

import useBreakpoints from '../hooks/useBreakpoints';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
    const mdUp = useBreakpoints('md', 'up');
    const xsOnly = useBreakpoints('xs', 'only');

    // NOTE: These will be got from external APIs or service
    const testimonials = {
        1: "MediRadar is intuitive and easy to use. Getting my knee MRI scan sorted quickly and efficiently helped rule out significant injury and gave me peace of mind. Otherwise, I would have waited for months for an appointment at my local NHS hospital",        
        2: "The website was easy to navigate on my mobile phone – a quick search, a few clicks and my scan was booked and confirmed!",
        3: "Allowing me to book my scan time, date and location directly is a definite bonus. It prevents the usual multiple phone calls needed to various private clinics or hospitals otherwise"
    };
    const settings = {
        dots: xsOnly ? true : false,
        speed: 500,
        autoplay: xsOnly ? true : false,
        slidesToShow: mdUp ? 2 : 1,
        slidesToScroll: mdUp ? 2 : 1,
        arrows: xsOnly ? false : true,
        nextArrow: xsOnly ? null : <ArrowForwardIcon />,
        prevArrow: xsOnly ? null : <ArrowBackIcon />
    };

    return (
        <Box sx={theme => ({
            p: '1rem',
            [`${theme.breakpoints.up('sm')}`]: {
                maxWidth: '85rem',
                margin: 'auto'
            }
        })}>
            <GlobalStyles styles={{
                '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': { color: 'black' },
                '.slick-prev, .slick-next': { color: 'black' }
            }}/>
            <Typography variant='h5' sx={(theme) => ({
                color: 'text.secondary',
                fontWeight: '100',
                [`${theme.breakpoints.down('sm')}`]: {
                    marginBottom: '1rem',
                    marginTop: '1rem'
                },
                [`${theme.breakpoints.up('sm')}`]: {
                    marginBottom: '3rem',
                    marginTop: '3rem'
                }
            })}>
                What our patients say
            </Typography>
            <Box sx={{padding: mdUp ? '3rem' : '1em'}}>
                <Slider {...settings}>            
                    {Object.keys(testimonials).map(i => (
                        <Box sx={{
                            display: 'flex !important',
                            width: 'auto !important',
                            marginLeft: '.5rem',
                            marginRight: '.5rem',
                            justifyContent: 'center'
                        }}
                            key={i}    
                        >
                            <Box sx={{
                                padding: '1rem'
                            }}>
                                <Typography variant={mdUp ? 'h4' : 'h5'} sx={{
                                    fontWeight: 100,
                                    textAlign: 'center'
                                }}>
                                    {`"${testimonials[i]}"`}
                                </Typography>
                                <br/>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <GppGoodOutlinedIcon color="primary" sx={{ marginRight: '.5rem' }}/>
                                    Verified patient
                                </Typography>
                            </Box>                            
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

export default Testimonials;