
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import SearchScans from './SearchScans';
import LocationSearch from './LocationSearch';
import useStores from '../stores/useStores';
import { observer } from "mobx-react-lite"

const FindConsultationTabPanelStyled = styled('div')(({ theme }) => ({
    marginTop: '1rem'
}));

const SearchContainerStyled = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    gap: '1rem'
}));

const IconButtonStyled = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
    backgroundColor: '#19BFB7',
    borderRadius: '.5rem',
    ':disabled': {
        opacity: '.5',
        color: '#19BFB7',
        backgroundColor: '#19BFB7'
    }
}));

const useStyles = makeStyles(theme => ({
    row: {
        margin: '1rem',
        marginTop: '2rem',
        '& h6': {
            fontWeight: 'bold'
        }
    },
    rowItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '1rem 0 1rem 0'
    },
    formControl: {
        flex: 1,
        margin: '0 1rem 0 1rem'
    },
    info: {
        color: '#ACACAC'
    },
    buttonDiv: {
        width: '100%',
        bottom: 0,
        padding: '1rem',
        position: 'fixed',
        // [theme.breakpoints.up('md')]: {
        //     width: containerWidth,
        //     maxWidth: containerMaxWidth,
        // }
    },
    popper: {
        padding: '.5rem',
        width: '15rem'
    },
    transparent: {
        color: 'transparent'
    },
    dialogTitleRoot: {
        paddingBottom: 0
    },
    dialogContentRoot: {
        paddingTop: 0
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        '& svg': {
            cursor: 'pointer'
        }
    }
}));

const FindScansTabPanel = observer(props => {
    const [openSelfReferralDialog, setOpenSelfReferralDialog] = useState(false);
    const [openReferalLetterDialog, setOpenReferalLetterDialog] = useState(false);
    const classes = useStyles();
    const { commonStore } = useStores();

    const navigateToDoctorList = () => {
        const {
            scanType,
            bodyRegion,
            bodyArea,
            latitude,
            longitude,
            otherGeolocationName,
            currentAddress,
            pinCode
        } = commonStore;

        const queryParams = `redirect=true&scanType=${scanType}`
            + `&bodyRegion=${bodyRegion}`
            + `&bodyArea=${bodyArea}`
            + `&latitude=${latitude}`
            + `&longitude=${longitude}`
            + `&otherGeolocationName=${otherGeolocationName}`
            + `&currentAddress=${currentAddress}`
            + `&currentAddress=${currentAddress}`
            + `&pinCode=${pinCode}`
            + `&path=doctors`;
        window.location = `${process.env.REACT_APP_SCANME_URL}?${queryParams}`;
    }

    const navigateToClinicsList = () => {
        redirectToScanMe();
    }

    const onSelfReferalDialogClose = () => {
        setOpenSelfReferralDialog(false);
    };

    const SelfReferralDialog = (props) => {
        return (
            <div>
                <Dialog open={openSelfReferralDialog} >
                    <DialogTitle className={classes.title}>
                        <CloseIcon onClick={onSelfReferalDialogClose}/>
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.dialogContentRoot }}>
                        <DialogContentText >
                            <Typography variant="h6">Do you wish to self refer?</Typography>
                            <Typography>(you can seek referral via a MediRadar consultant/specialist or GP if unsure)</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={navigateToDoctorList}
                            color='inherit'
                        >
                            Diagnostic consultation
                        </Button>
                        <Button style={{
                            fontWeight: 600
                        }}
                            color="primary"
                            onClick={navigateToClinicsList}
                        >
                            Self refer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    const onReferalLetterDialogClose = () => {
        setOpenReferalLetterDialog(false);
    };

    const ReferalLetterDialog = (props) => {
        return (
            <div>
                <Dialog open={openReferalLetterDialog} >
                    <DialogTitle className={classes.title}>
                        <CloseIcon onClick={onReferalLetterDialogClose}/>
                    </DialogTitle>
                    <DialogContent classes={{ root: classes.dialogContentRoot }}>
                        <DialogContentText >
                            <Typography variant="h6">Do you have referral letter or request form?</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={navigateToDoctorList}
                            color='inherit'
                        >
                            No
                        </Button>
                        <Button style={{
                            fontWeight: 600
                        }}
                            color="primary"
                            onClick={navigateToClinicsList}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    const redirectToScanMe = () => {
        const {
            scanType,
            bodyRegion,
            bodyArea,
            latitude,
            longitude,
            otherGeolocationName,
            currentAddress,
            pinCode
        } = commonStore;

        const queryParams = `redirect=true&scanType=${scanType}`
            + `&bodyRegion=${bodyRegion}`
            + `&bodyArea=${bodyArea}`
            + `&latitude=${latitude}`
            + `&longitude=${longitude}`
            + `&otherGeolocationName=${otherGeolocationName}`
            + `&currentAddress=${currentAddress}`
            + `&currentAddress=${currentAddress}`
            + `&pinCode=${pinCode}`
            + `&path=clinics`;
        window.location = `${process.env.REACT_APP_SCANME_URL}?${queryParams}`;
    };

    const handleSelfReferralDialogOpen = () => {
        const rxFlag = commonStore.modalities[commonStore.scanType].rxFlag;
        setOpenSelfReferralDialog(!rxFlag);
        setOpenReferalLetterDialog(rxFlag);
    };

    return (
        <FindConsultationTabPanelStyled>
            <LocationSearch />
            <SearchContainerStyled>
                <SearchScans></SearchScans>
                <IconButtonStyled
                    disabled = {commonStore?.pinCode?.length === 0 || commonStore?.scanType?.length === 0}
                    type="submit"
                    sx={{ p: '.5rem 1rem' }}
                    aria-label="search"
                    color="primary"
                    onClick={handleSelfReferralDialogOpen}
                >                    
                    <Typography sx={{ color: '#fff' }}>Search</Typography>
                </IconButtonStyled>
            </SearchContainerStyled>
            <SelfReferralDialog></SelfReferralDialog>
            <ReferalLetterDialog></ReferalLetterDialog>
        </FindConsultationTabPanelStyled>
    );
});

export default FindScansTabPanel;