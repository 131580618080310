import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';

import useStores from '../stores/useStores';

export default function AuthorBio(props) {
    const { target, onClose, author } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { commonStore } = useStores();

    React.useEffect(() => {
        setAnchorEl(target);
    }, [target])

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom'
            }}
        >
            <Box sx={{
                p: '1rem',
                maxWidth: '30rem'
            }}>
                <Typography variant="body2" color="text.secondary" sx={{                        
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    '& img': {
                        width: '4rem',
                        height: '4rem',
                        objectFit: 'cover',
                        borderRadius: '50%'
                    }
                }}>
                    <img src={author.photo} alt={author.name}/>
                    <h3>{author.name}</h3>
                </Typography>
                <Divider sx={{ mb: '.5rem' }}/>
                <Typography textColor="secondary" sx={{
                    '& *': {
                        m: 0,
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: '.825rem'
                    }
                }}>
                    <div dangerouslySetInnerHTML={{__html: author.bio || commonStore.authorBio}} />
                </Typography>
            </Box>
        </Popover>
    );
};