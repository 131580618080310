import React from 'react';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import PinCodeSearch from './PinCodeSearch';

const LocationSearch = props => {
    const { sx } = props;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            right: 0,
            alignSelf: 'center',
            cursor: 'pointer',
            marginBottom: '1rem',
            '& input, input:focus': {
                border: 'none',
                outline: 'none'
            },
            ...sx
        }}>
            <GlobalStyles style={{
                '.pac-container': {
                    boxShadow: `
                        0rem 0.1875rem 0.3125rem -0.0625rem rgb(0 0 0 / 20%),
                        0rem 0.3125rem 0.5rem 0rem rgb(0 0 0 / 14%),
                        0rem 0.0625rem 0.875rem 0rem rgb(0 0 0 / 12%)
                    `
                }
            }} />
            <PinCodeSearch> </PinCodeSearch>
        </Box>
    );
};

export default LocationSearch;