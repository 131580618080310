import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',

// Todo
        // padding: theme.spacing(3),
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    link: {
        flex: 1,
        // Todo
        // [theme.breakpoints.down('sm')]: {
        //     marginBottom: '1rem',
        //     flexBasis: '100%'
        // }
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const FaqsAndPolicies = (props) => {    
    const classes = useStyles();
    const { open, handleClose } = props;

    return (
        <React.Fragment>
            <Dialog fullScreen onClose={handleClose} open={open}>
                <DialogTitle disableTypography className={classes.title}>
                    <Typography variant="h5">Faqs and Policies for Mediradar</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="h5">
                        Privacy statement for Websites
                    </Typography>
                    <Typography>
                        <p> MediRadar(mediradar.com)/MediRadar Limited is committed to ensuring the privacy and confidentiality of your personal information, and to protect it from unauthorised access and disclosure.</p>
                        <p> The purpose of this Privacy Policy is to clearly communicate to you how MediRadar Limited handles your personal information to provide you with services, and to comply with legal obligations. This Privacy Policy applies to all websites owned or operated by MediRadar Limited, as amended from time to time (the “Websites”).This Privacy Policy (together with our terms and conditions of use of the website will give you a better and more complete understanding of the type of personal information that MediRadar Limited holds about you and the way MediRadar Limited handles that information.</p>
                        <p> MediRadar Limited complies with the Data Protection Act 1998, and the General Data Protection Regulation 2016/679 (“GDPR”) and any local or European laws on data protection. as amended from time to time. MediRadar Limited and is registered as a data controller as defined in the GDPR with the data protection regulator in the UK, the Information Commissioner’s Office “ICO”), www.ico.org.uk.</p>
                    </Typography>
                    <br />
                    <Typography variant="h6">
                        How we use your information
                    </Typography>
                    <Typography>
                        <p> This privacy policy tells you what to expect when MediRadar Limited collects personal information. It applies to information we collect about:</p>
                        <ul>
                            <li>
                                <p>Visitors to our Websites.</p>
                                <p>
                                    Visitors who visit our website to do an imaging facility search and price comparison.
                                </p>
                                <p> MediRadar Limited collects data on visitors to its Websites including usage and behaviour patterns via a third party service, Google Analytics (Google LLC). This is to review the number of visits to each part of the Website and how the user accessed the Website only. Information collected, which may contain Personal Information, includes:</p>
                            </li>
                            <li>
                                <p>Date and time of visit.</p>
                                <p>Pages accessed.</p>
                                <p>Browser or mobile platform used to access the Website.</p>
                                <p>Source used to find and access the Website (i.e. Google Search Engine); </p>
                                <p>Location of the visitor (town level only).</p>
                                <p>Search queries from external and internal search engines.</p>
                                <p>Page interaction information including aggregated contact form completions; and technical information, including browser type and version, operating system and platform.</p>
                                <p>When you use our Website, we do not attempt to identify you as an individual user and we will not collect personal information about you unless you specifically provide this to us.</p>
                                <p>We may collect your personal information if you choose to provide this to us via an online form or by email. Examples are where you:</p>
                                <p>Submit your details on our user forms.</p>
                                <p>Register to receive email Newsletters.</p>
                                <p>Make an enquiry via a telephone number displayed on the Website; or</p>
                                <p>Send a written complaint or enquiry to our Data Protection Officer</p>
                            </li>
                        </ul>
                    </Typography>


                    <br/> <br/>
                    <Typography variant="h5">Cookie Policy for MediRadar</Typography>

                    <Typography >
                        <p>This is the Cookie Policy for MediRadar, accessible from https://mediradar.com/</p>
                    </Typography>
                    <br />
                    <Typography >
                        <ul style={{ "listStyleType": "decimal" }}>
                            <li>
                                <Typography variant="h6">Cookie Policy</Typography >
                                <p> Our website uses cookies to distinguish you from other users of the mediradar.com website (“the Site”). This helps us to provide you with a good experience when you browse our site and also allows us to improve our site. By continuing to browse our site, you are agreeing to our use of cookies.</p>
                            </li>
                            <li>
                                <Typography variant="h6">What is a cookie</Typography>
                                <p> A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
                            </li>
                            <li>
                                <Typography variant="h6">How do we use cookies</Typography >
                                <p> We will use the information collected to make our website more relevant to your interests.</p>
                                <p> We may also share this information with third parties for this purpose.</p>
                                <p> We use the following types of cookie:</p>
                                <p> Essential cookies. These are cookies that are required for the operation of our website</p>
                                <p> Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it.</p>
                                <p> Functionality cookies. These are used to recognise you when you return to our website.</p>
                                <p> Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed.</p>
                                <p> Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies</p>
                            </li>
                            <li>
                                <Typography variant="h6">Managing Cookies</Typography>
                                <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
                            </li>
                        </ul>
                    </Typography>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default FaqsAndPolicies;
