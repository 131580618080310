import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom"

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import dogImage from '../assets/about-image-1.svg';

const ImageHolderStyled = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const AboutStatic = () => {
    const [expand, setExpand] = useState();
    const [aboutus, setAboutus] = useState(false);
    const location = useLocation();

    const expandAboutMe = (e, flag) => {
        e.preventDefault();
        setExpand(flag);
    };

    useEffect(() => {
        if (location?.pathname?.includes('aboutus')) {

            (async() => {
                const result = await fetch(
                    // 'https://api.dropinblog.com/v1/json/?b=77525ecd-de31-4e80-921d-e1d30e433cd4&includecontent=1&post=about-us'
                    'https://api.dropinblog.com/v1/json/post/?b=77525ecd-de31-4e80-921d-e1d30e433cd4&post=about-us'
                );
                const aboutus = await result.json();
                setAboutus(aboutus);
            })();
        }   
    }, [location]);

    return (
        <Box sx={{
            p: '1rem',
            backgroundColor: '#DCF1F0'
        }}>
            {aboutus ? (
                 <Box sx={{
                    maxWidth: '85rem',
                    margin: 'auto'
                }}>
                     <Typography variant='h5' sx={(theme) => ({
                        color: 'text.secondary',
                        fontSize: '2rem',
                        fontWeight: 'bold'
                    })}>
                        About Us
                    </Typography>
                    <div dangerouslySetInnerHTML={{__html: aboutus?.data?.post?.content}} />
                </Box>
            ) : (
                <Box sx={{
                    maxWidth: '85rem',
                    margin: 'auto'
                }}>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        padding: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        [`${theme.breakpoints.down('sm')}`]: {
                            flexDirection: 'column-reverse'
                        }
                    })}>
                        <Typography sx={{ flexBasis: '70%' }}>                        
                            <>
                                <Typography variant='h5' sx={(theme) => ({
                                    color: 'text.secondary',
                                    fontSize: '2rem',
                                    fontWeight: 'bold'
                                })}>
                                    About Us
                                </Typography>
                                <p>
                                    Modern healthcare demands accuracy and certainty, with a greater reliance on medical imaging using various techniques:
                                    magnetic resonance imaging (MRI), computed tomography (CT) and ultrasound being the most common types of scans used. 
                                    Early investigation and imaging allows individuals to be given a fast diagnosis and early treatment. 
                                </p>
                                <p>
                                    Our passion is to improve accessibility to high end private healthcare at an affordable price for people who cannot wait for long NHS wait times
                                    and need answers to their symptoms urgently. We aim to deliver a seamless experience and provide the first steps towards recovery and better health. 
                                    {' '}
                                    {!expand && <Link href="#" onClick={e => expandAboutMe(e, true)}>
                                        Read more...
                                    </Link>}
                                </p>
                            </>
                        </Typography>
                        <ImageHolderStyled sx={{ flexBasis: '30%' }}>
                            <img src={dogImage} alt="Man with dog" width="100%" height="100%" />
                        </ImageHolderStyled>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AboutStatic;