import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import HomePageContent from './HomePageContent';
import PageNavigationTabs from './PageNavigationTabs';

import useSmUp from './../hooks/useSmUp';
import dogImage from '../assets/about-image-1.svg';

const HomePageMainSearchStyled = styled('div')(({ theme }) => ({
    [`${theme.breakpoints.up('sm')}`]: {
        maxWidth: '85rem',
        margin: 'auto'
    }
}));

const ImageHolderStyled = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        clipPath: 'inset(40px 40px 0)'
    }
}));

const HomePageMainSearch = props => {
    const matches = useSmUp();

    return (
        <HomePageMainSearchStyled>
            <Grid container spacing={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Grid item xs={12} sm={6} sx={(theme) => ({
                    [`${theme.breakpoints.only('xs')}`]: {
                        order: 2
                    }
                })}>
                    <HomePageContent/>
                    {matches && <PageNavigationTabs/>}
                </Grid>
                <Grid item xs={12} sm={6} sx={(theme) => ({
                    [`${theme.breakpoints.down('md')}`]: {
                        alignSelf: 'flex-start'
                    },
                    [`${theme.breakpoints.only('xs')}`]: {
                        order: 1,
                        '& img': {
                            marginLeft: 0,
                        }
                    }
                })}>
                    <ImageHolderStyled>
                        <img src={dogImage} alt="Man with physio" width="100%" height="100%" />
                    </ImageHolderStyled>
                </Grid>
                {!matches && 
                    <Grid item xs={12} sx={(theme) => ({
                        [`${theme.breakpoints.only('xs')}`]: {
                            order: 3
                        }
                    })}>
                        <PageNavigationTabs/>
                    </Grid>
                }
            </Grid>
        </HomePageMainSearchStyled>
    );
};

export default HomePageMainSearch;